const queries = new Map()

// FRAGMENTS
const FRAGMENT_BOILERPLATE = `
    title
    overline
    content
    focustext
`
const FRAGMENT_BOILER_BASIC = `
    id
    title
    content
`
const FRAGMENT_RATIOS = `ratios {
    scale
    value
}`
const FRAGMENT_GRID = `grid {
    gap
    span
    cols
}`
const FRAGMENT_GRID_GAP = `grid {
    gap
}`
const FRAGMENT_GRID_SPAN = `grid {
    span
}`
const FRAGMENT_DISPLAY = `display {
    showtitle
    compact
    stackable
    displayoverline
    displaycontent
    displaylinks
    backgroundcolor,
    typescale
    override
}`
const FRAGMENT_DISPLAY_BASIC = `display {
    showtitle
    displaycontent
    displaylinks
}`
const FRAGMENT_LINK = `link {
    key
    title
    url
    css
    openinnewwindow
}`
const FRAGMENT_LINKS = `links {
    key
    title
    url
    css
    openinnewwindow
}`
const FRAGMENT_TAXONOMY = `taxonomies {
    id
    name
    urlsegment
    typeid
}`
const FRAGMENT_MAP = `map {
    uuid
    title
    formatted_address
    address {
        street
        city
        province
        postalcode
        country
        phone
        url
    }
    geometry {
        location {
            lat
            lng
        }
        location_type
        viewport {
            south
            west
            north
            east
        }
    }
    place_id
}`
const FRAGMENT_MAP_SYMBOL = `map {
    id
    uuid
    alias
    interactive
    description
    type
    geometry {
        data
        location {
            lat
            lng
        }
        viewbox {
            x
            y
            w
            h
        }
    }
}`
const FRAGMENT_LINKGROUPS = `linkgroups {
    key
    links {
        key
        title
        description
        openinnewwindow
        url
    }
}`
const FRAGMENT_SOURCES = `sources {
    image
    breakpointatt
    mimetype
    width
    height
}`
const FRAGMENT_ASSETS = `assets {
    id
    alt
    url
    thumbnail
    load
    backgroundcolor
    ${FRAGMENT_SOURCES}
}`
const FRAGMENT_IMAGE = `image {
    id
    alt
    url
    thumbnail
    load
    ${FRAGMENT_SOURCES}
}`
const FRAGMENT_CONTENTPROPS = `contentprops {
    key
    value
}`
const FRAGMENT_STYLE = `style {
    key
    value
}`
const FRAGMENT_VIDEO = `videobackground {
    id
    url
    type
    status
    delay
}`
const FRAGMENT_LAYOUT = `layout {
    id
    label
    alignment
    aligncontent
    collapsetop
    collapsebottom
    elevation
    animations
    template
    ${FRAGMENT_CONTENTPROPS}
}`
const FRAGMENT_LAYOUT_VIDEO = `layout {
    id
    label
    alignment
    aligncontent
    collapsetop
    collapsebottom
    elevation
    animations
    template
    ${FRAGMENT_CONTENTPROPS}
    ${FRAGMENT_VIDEO}
}`
const FRAGMENT_LAYOUT_BASIC = `layout {
    id
    ${FRAGMENT_CONTENTPROPS}
}`
const FRAGMENT_LAYOUT_STYLE = `layout {
    id
    label
    alignment
    aligncontent
    collapsetop
    collapsebottom
    elevation
    animations
    template
    ${FRAGMENT_CONTENTPROPS}
    ${FRAGMENT_STYLE}
}`

const FRAGMENT_GLIDER = `glider {
    controls
    slidesToShow
    slidesToScroll
    scrollLock
    scrollLockDelay
    resizeLock
    scrollPropagate
    eventPropagate
    autoplay
    autoplaydelay
    loop
    draggable
    dragVelocity
    itemWidth
    exactWidth
    duration
    effect
}`

const FRAGMENT_TAXONOMY_ASSET = `
    name
    urlsegment
    description
    count
    asset {
        id
        sm
        mm
    }
`

// TYPE QUERIES
queries.set('readFeature', {
    id: 'readFeature',
    query: (params) => {
        return {query: `
            query {
                readFeature (id: ${params?.id ?? null}) {
                    id
                    tollFree
                    title
                    urlsegment
                    url
                    address
                    city
                    province
                    postalCode
                    phone
                    description
                    dishes
                    socials {
                        platform
                        url
                    }
                    ${FRAGMENT_IMAGE}
                    gallery {
                        id
                        sm
                        mm
                        lg
                    }
                }
            }
        `}
    }
})

queries.set('readFeatures', {
    id: 'readFeatures',
    query: (params) => {
        return {query: `
            query {
                readFeatures (limit: ${params?.id ?? -1}, terms: "${Array.isArray(params?.terms) ? params?.terms?.join(',') : (params?.terms ?? '')}") {
                    id
                    tollFree
                    title
                    urlsegment
                    url
                    address
                    city
                    province
                    postalCode
                    phone
                    regions
                    collections
                    latitude
                    longitude
                    trie {
                        title
                        class
                        regions
                        categories
                    }
                    asset {
                        id
                        sm
                        mm
                        lg
                    }
                    geometry {
                        type
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                }
            }
        `}
    }
})

queries.set('readRegions', {
    id: 'readRegions',
    query: (params) => {
        return {query: `
            query {
                readRegions (limit: ${params?.id ?? -1}, type: "Region") {
                    name
                }
            }
        `}
    }
})

queries.set('readTaxonomyGroup', {
    id: 'readTaxonomyGroup',
    query: (params) => {
        return {query: `
            query {
                readTaxonomyGroup (limit: ${params?.id ?? -1}, count: ${params?.count ?? true}, group: "${params?.group ?? 'spice-trail-categories'}") {
                    ${FRAGMENT_TAXONOMY_ASSET}
                }
            }
        `}
    }
})

queries.set('readTaxonomy', {
    id: 'readTaxonomy',
    query: (params) => {
        return {query: `
            query {
                readTaxonomy (type: "${params?.type ?? 'category'}") {
                    ${FRAGMENT_TAXONOMY_ASSET}
                }
            }
        `}
    }
})

// COMPONENT QUERIES
/**
 * General component query
 */
queries.set('readComponent', {
    id: 'readComponent',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${(params?.component == 'UIElementalPageHeader') ? FRAGMENT_LAYOUT_VIDEO : FRAGMENT_LAYOUT_STYLE}
                        ${FRAGMENT_LINKS}
                        image {
                            id
                            alt
                            url
                            thumbnail
                            load
                            sources {
                                image
                                breakpointatt
                                mimetype
                                width
                                height
                            }
                        }
                    }
                }
            }`
        }
    }
})

/**
 * Read partner map configuration
 */
queries.set('readPartnerMap', {
    id: 'readPartnerMap',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT_STYLE}
                        ${FRAGMENT_LINKS}
                        ${FRAGMENT_TAXONOMY}
                    }
                }
            }`
        }
    }
})

/**
 * General component query
 */
queries.set('readExperience', {
    id: 'readExperience',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_ASSETS}
                        ${FRAGMENT_LINK}
                        ${FRAGMENT_LINKS}
                    }
                }
            }`
        }
    }
})

/**
 * Global component query, e.g. Appbar and Footer
 */
queries.set('readGlobalComponent', {
    id: 'readGlobalComponent',
    query: (params) => {
        return {query: `
            query {
                readComponent(class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_LAYOUT}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_GRID_SPAN}
                                ${FRAGMENT_DISPLAY}
                                layout {
                                    key
                                    ${FRAGMENT_CONTENTPROPS}
                                }
                                ${FRAGMENT_LINKS}
                            }
                        }
                        ${FRAGMENT_LINKGROUPS}
                    }
                }
            }`
        }
    }
})

/**
 * Triptych
 */
queries.set('readTriptych', {
    id: 'readTriptych',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_ASSETS}
                        ${FRAGMENT_LINKS}
                    }
                }
            }`
        }
    }
})

/**
 * Mosaic
 */
queries.set('readMosaic', {
    id: 'readMosaic',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                            }
                        }
                    }
                }
            }`
        }
    }
})

/**
 * Grid
 */
queries.set('readGrid', {
    id: 'readGrid',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                            }
                        }
                    }
                }
            }`
        }
    }
})

/**
 * Partner List
 */
queries.set('readPartnerList', {
    id: 'readPartnerList',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        ${FRAGMENT_TAXONOMY}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_TAXONOMY}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                            }
                        }
                    }
                }
            }`
        }
    }
})

/**
 * Grid
 */
queries.set('readGrid', {
    id: 'readGrid',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                            }
                        }
                    }
                }
            }`
        }
    }
})

queries.set('readCarouselContent', {
    id: 'readCarouselContent',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GLIDER}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID_SPAN}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT_BASIC}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                            }
                        }
                    }
                }
            }`
        }
    }
})

queries.set('readListingContent', {
    id: 'readListingContent',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID_SPAN}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT_BASIC}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                                ${FRAGMENT_TAXONOMY}
                            }
                        }
                    }
                }
            }`
        }
    }
})

queries.set('readGalleryCollection', {
    id: 'readGalleryCollection',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GLIDER}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        ${FRAGMENT_ASSETS}
                    }
                }
            }`
        }
    }
})

queries.set('readSymbolCollection', {
    id: 'readSymbolCollection',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_MAP_SYMBOL}
                            }
                        }
                    }
                }
            }`
        }
    }
})

queries.set('readMapComponent', {
    id: 'readMapComponent',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GLIDER}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY}
                        ${FRAGMENT_LAYOUT}
                        ${FRAGMENT_LINKS}
                        components {
                            component
                            props {
                                ${FRAGMENT_BOILER_BASIC}
                                ${FRAGMENT_RATIOS}
                                ${FRAGMENT_GRID_SPAN}
                                ${FRAGMENT_DISPLAY}
                                ${FRAGMENT_LAYOUT_BASIC}
                                ${FRAGMENT_LINKS}
                                ${FRAGMENT_IMAGE}
                                ${FRAGMENT_MAP}
                            }
                        }
                    }
                }
            }`
        }
    }
})

/**
 * Blog Title Block
 */
queries.set('readBlogTitle', {
    id: 'readBlogTitle',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_GRID}
                        display {
                            showtitle
                        }
                        ${FRAGMENT_LAYOUT}
                    }
                }
            }`
        }
    }
})

/**
 * Crowdriff Block
 */
queries.set('readCrowdriffComponent', {
    id: 'readCrowdriffComponent',
    query: (params) => {
        return {query: `
            query {
                readComponent(id: ${params?.id ?? 0}, componentid: ${params?.componentid ?? 0}, class: "${params?.component ?? null}") {
                    component
                    props {
                        ${FRAGMENT_BOILERPLATE}
                        ${FRAGMENT_RATIOS}
                        ${FRAGMENT_GRID}
                        ${FRAGMENT_DISPLAY_BASIC}
                        ${FRAGMENT_LAYOUT}
                    }
                }
            }`
        }
    }
})

export const getQuery = (id) => queries.has(id) ? queries.get(id)?.query : () => {}
